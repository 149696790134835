import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import VueLazyload from 'vue-lazyload'
// 配置项
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   attempt: 1
// })
// import 'lib-flexible/flexible.js'
Vue.use(ElementUI);
import './style/Normalize.css'
// import './assets/css/screen.css'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
