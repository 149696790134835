import Vue from 'vue'
import VueRouter from 'vue-router'
// 解决重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/homePage/index'
  },
  {
    path: '/homePage',
    component: () => import('../views/layout/index'),
    // redirect: '/homePage/index',
    children: [
      {
        path: 'index',
        name: 'homePage',
        component: () => import('@/views/homePage/index'),
        meta: {
          title: '首页'
        }
      }
    ]
  },
  // {
  //   path: '/introduce',
  //   component: () => import('../views/layout/index'),
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'introduce',
  //       component: () => import('@/views/introduce/index'),
  //       meta: {
  //         title: '公司介绍'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/business',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'business',
        component: () => import('@/views/business/index'),
        meta: {
          title: '鉴定业务'
        }
      }
    ]
  },
  {
    path: '/programme',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'programme',
        component: () => import('@/views/programme/index'),
        meta: {
          title: '解决方案'
        }
      }
    ]
  },
  {
    path: '/contactUs',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'contactUs',
        component: () => import('@/views/contactUs/index'),
        meta: {
          title: '联系我们'
        }
      }
    ]
  },
  {
    path: '/data',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'data',
        component: () => import('@/views/data/index'),
        meta: {
          title: '数据分析'
        }
      }
    ]
  },
  {
    path: '/journalism',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'journalism',
        component: () => import('@/views/journalism/index'),
        meta: {
          title: '新闻动态'
        }
      }
    ]
  },
  {
    path: '/journalism',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'details',
        name: 'journalism',
        component: () => import('@/views/journalism/details'),
        meta: {
          title: '新闻动态'
        }
      }
    ]
  },
  {
    path: '/cracking',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'cracking',
        component: () => import('@/views/cracking/index'),
        meta: {
          title: '密码破解'
        }
      }
    ]
  },
  {
    path: '/publicInfocution',
    component: () => import('../views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'publicInfocution',
        component: () => import('@/views/publicInfocution/index'),
        meta: {
          title: '信息公示'
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
